/* Describes all item taypes, used in dataModels.js.
   Some item types are used in different entries. For instance DSA is used in both DSA and FD entries.
   DSF is used in DSF, FLA and FVA.

   Each itemType contains
   - an identifier (str)
   - an array which lists the fields available on each line of this item type
        - label: diaplsy label
        - name: used for the name attribute in ReduxForm
        - fieldType: used in RenderField.js to display the html code of this field. it can be an html field type (text,...) or a custom type which is handled in RenderField
        - disableOnAmountInput: boolean, if true, this field must be disabled when the user wants to input the line amount directly, without calculation
        - computedAMount: boolean, if true, this field must be taken into account to calculate the line amount
        - append: str, suffix to display after the html field in the frontend
*/

export const itemTypes = new Map();
itemTypes.set("DSA", [{
  label: "Intitulé de la dépense",
  name: "label",
  fieldType: "text",
},
{
  label: "Date ou période de la dépense",
  name: "date",
  fieldType: "datePeriod",
},
]);
itemTypes.set("AUTPast", [{
  label: "Intitulé de la dépense",
  name: "label",
  fieldType: "text",
},
{
  label: "Composante(s)",
  name: "component",
  fieldType: "longText",
},
{
  label: "Date ou période de la dépense",
  name: "date",
  fieldType: "datePeriod",
},
]);
itemTypes.set("AUTFuture", [{
  label: "Intitulé de la dépense",
  name: "label",
  fieldType: "text",
},
{
  label: "Composante(s)",
  name: "component",
  fieldType: "longText",
},
]);
itemTypes.set("TPT", [{
  label: "Date de début",
  name: "startDate",
  fieldType: "date",
},
{
  label: "Date de fin",
  name: "endDate",
  fieldType: "date",
},
{
  label: "Durée",
  name: "duration",
  fieldType: "duration",
  placeholder: "0",
  disableOnAmountInput: true,
  computedAmount: true,
},
{
  label: "Heures",
  name: "hours",
  fieldType: "refNumber",
  placeholder: "0",
  step: 0.5,
  decimalScale: 1,
  max: 99,
  append: "h",
  disableOnAmountInput: true,
  computedAmount: true,
},
{
  label: "Taux horaire",
  name: "periodRate",
  fieldType: "amount",
  disableOnAmountInput: true,
  computedAmount: true,
},
]);
itemTypes.set("PGPA", [{
  label: "Date de début",
  name: "startDate",
  fieldType: "date",
},
{
  label: "Date de fin",
  name: "endDate",
  fieldType: "date",
},
{
  label: "Durée",
  name: "duration",
  fieldType: "duration",
  placeholder: "0",
  disableOnAmountInput: true,
  computedAmount: true,
},
{
  label: "Revenu de référence",
  name: "periodRate",
  fieldType: "refAmount",
  disableOnAmountInput: true,
  computedAmount: true,
},
{
  label: "Montant à déduire",
  name: "workIncome",
  fieldType: "workIncome",
  disableOnAmountInput: true,
  computedAmount: true,
},
{
  label: "Commentaire",
  name: "label",
  fieldType: "text",
},
]);
itemTypes.set("DSF", [{
  label: "Intitulé",
  name: "label",
  fieldType: "text",
},
{
  label: "Fréquence de renouvellement",
  name: "frequency",
  fieldType: "frequency",
  placeholder: "0.00",
  disableOnAmountInput: true,
  computedAmount: true,
},
{
  label: "Coût à chaque acquisition",
  name: "acquisitionCost",
  fieldType: "amount",
  disableOnAmountInput: true,
  computedAmount: true,
},
]);
itemTypes.set("TPF", [{
  label: "Heures",
  name: "hours",
  fieldType: "number",
  placeholder: "0.0",
  step: 0.5,
  append: "h",
  disableOnAmountInput: true,
  computedAmount: true,
},
{
  label: "Type de période",
  name: "periodType",
  fieldType: "periodType",
  disableOnAmountInput: true,
  computedAmount: true,
},
{
  label: "Taux horaire",
  name: "periodRate",
  fieldType: "amount",
  disableOnAmountInput: true,
  computedAmount: true,
},
{
  label: "Intitulé",
  name: "label",
  fieldType: "text",
},
]);
itemTypes.set("PGPFProj", [
  {
    label: "Revenu de référence",
    name: "periodRate",
    fieldType: "refAmount",
    disableOnAmountInput: true,
    computedAmount: true,
  },
  {
    label: "Montant à déduire",
    name: "workIncome",
    fieldType: "workIncomeProj",
    disableOnAmountInput: true,
    computedAmount: true,
  },
  {
    label: "Description",
    name: "label",
    fieldType: "text",
  },
]);
itemTypes.set("SE", [{
  label: "Taux de préjudice",
  name: "damageRate",
  fieldType: "sevenPtsRate",
},
{
  label: "Composante(s)",
  name: "label",
  fieldType: "longText",
},
]);
itemTypes.set("DFT", [{
  label: "Date de début",
  name: "startDate",
  fieldType: "date",
},
{
  label: "Date de fin",
  name: "endDate",
  fieldType: "date",
},
{
  label: "Précisions",
  name: "label",
  fieldType: "text",
},
{
  label: "Durée",
  name: "duration",
  fieldType: "duration",
  placeholder: "0",
  disableOnAmountInput: true,
  computedAmount: true,
},
{
  label: "Taux de préjudice",
  name: "damageRate",
  fieldType: "number",
  placeholder: "0",
  step: 1,
  max: 100,
  append: "%",
  disableOnAmountInput: true,
  computedAmount: true,
},
{
  label: "Montant journalier",
  name: "periodRate",
  fieldType: "amount",
  disableOnAmountInput: true,
  computedAmount: true,
},
]);
itemTypes.set("DFP", [{
  label: "Taux",
  name: "damageRate",
  fieldType: "number",
  placeholder: "0",
  step: 1,
  max: 100,
  append: "%",
  disableOnAmountInput: true,
  computedAmount: true,
},
{
  label: "Précisions",
  name: "label",
  fieldType: "longText",
},
{
  label: "Valeur du point",
  name: "pointValue",
  fieldType: "amount",
  disableOnAmountInput: true,
  computedAmount: true,
},

]);
itemTypes.set("PA", [{
  label: "Composante(s)",
  name: "label",
  fieldType: "longText",
}]);
itemTypes.set("IP", [{
  label: "Composante(s)",
  name: "label",
  fieldType: "text",
}]);
itemTypes.set("DIV", [{
  label: "Intitulé",
  name: "label",
  fieldType: "text",
}]);
itemTypes.set("DIVDate", [
  {
    label: "Date",
    name: "startDate",
    fieldType: "date",
  },
  {
  label: "Intitulé",
  name: "label",
  fieldType: "text",
}]);
itemTypes.set("PAcc", [{
  label: "Intitulé du préjudice",
  name: "label",
  fieldType: "text",
},
{
  label: "Période du préjudice",
  name: "date",
  fieldType: "datePeriod",
},
]);
itemTypes.set("PRev", [{
  label: "Intitulé",
  name: "label",
  fieldType: "text",
},
]);
