


// Check token and load user
import { TEAM_LOADED, TEAM_LOADING } from "./types";
import axios from "axios";
import { tokenConfig } from "./auth";
import { returnErrors } from "./errors";

export const getTeamsAndMembers = () => (dispatch, getState) => {
    // User loading
    dispatch({ type: TEAM_LOADING });

    axios
        .get("/api/my-teams/", tokenConfig(getState))
        .then(res => {
            dispatch({
                type: TEAM_LOADED,
                payload: res.data,
            });
        })
         .catch(err =>
            dispatch(returnErrors(err.response.data, err.response.status)),
        );
};
