import React from 'react';
import PropTypes from 'prop-types';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { Field, reduxForm } from "redux-form";

import RenderGenderChoice from "../creation_wizard/RenderGenderChoice";
import RenderInput from "../creation_wizard/RenderInput"
import DatePicker, {
    formatDates,
    normalizeDates,
  } from "../creation_wizard/RenderDateInput";

import { makeStyles } from '@material-ui/core/styles';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import { familyLinkTypes } from '../constants';

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: 'none',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        textAlign: 'left',
        maxWidth:500,
        minWidth:500,
    },
}));

const required = value => (value ? undefined : "Champ obligatoire");



let IndirectVictimBtn = props => {
    const {
        handleSubmit,
        handleAddVictimForm,
        open,
        isLocked,
      } = props;

    // const dispatch = useDispatch();
    const classes = useStyles();
    const [linkType, setLinkType] = React.useState("spouse")
    const [otherLinkType, setOtherLinkType] = React.useState("")
    const [firstName, setFirstName] = React.useState("")
    const [lastName, setLastName] = React.useState("")
    const [gender, setGender] = React.useState("")
    const [birthDate, setBirthDate] = React.useState("")
    const handleOpen = () => {
        handleAddVictimForm(true);
    };
    const handleClose = () => {
        handleAddVictimForm(false);
    };
    const handleLinkTypeChange = (event, newValue) => {
        setLinkType(newValue)
    }
    const handleFirstNameChange = (event, newValue) => {
        setFirstName(newValue)
    }
    const handleLastNameChange = (event, newValue) => {
        setLastName(newValue)
    }
    const handleOtherLinkTypeChange = (event, newValue) => {
        setOtherLinkType(newValue)
    }
    const handleGenderChange = (event, newValue) => {
        setGender(newValue)
    }

    const handleBirthDateChange = (event, newValue) => {
        setBirthDate(newValue)
    }

    let currentGender = 0;
    if (gender === "F") currentGender = 1;

  return (
    <div className="ml-auto">
        <button
          disabled={props.isLocked}
          type="button"
          className="btn btn-dark btn-sm mt-1 ml-2"
          onClick={() => handleOpen()}
          title="Ajouter une victime indirecte (version bêta)"
        >
            <div className='text-nowrap'><SupervisedUserCircleIcon /> Ajouter&nbsp;une&nbsp;victime&nbsp;indirecte<br/>(version bêta)</div>
        </button>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <fieldset className={classes.paper}>
                        <h2 id="transition-modal-title" style={{ textAlign:'center' }}>Ajouter une victime indirecte&nbsp;<small style={{ fontSize:"0.8rem" }}><i>(version bêta)</i></small></h2>
                        <form onSubmit={handleSubmit} id="indirect-victim-form" className="h-100 d-flex flex-column">
                            <div className='form-group input-group pt-4 indirect-victim-form'>
                                <Field
                                  label="Nom"
                                  name="last_name"
                                  component={RenderInput}
                                  type="text"
                                  placeholder="Nom de famille"
                                  optional={true}
                                  onChange={handleLastNameChange}
                                  className=" pr-4"
                                />
                                <Field
                                  label="Prénom"
                                  name="first_name"
                                  component={RenderInput}
                                  type="text"
                                  placeholder="Prénom"
                                  className=" pl-4"
                                  optional={true}
                                  onChange={handleFirstNameChange}
                                />
                            </div>
                            <div className='form-group input-group indirect-victim-form' style={{ paddingLeft:'-20px' }}>
                                <div className='gender pr-1'>
                                    <Field
                                      name="victimGender"
                                      component={RenderGenderChoice}
                                      validate={required}
                                      mandatory={true}
                                      onChange={handleGenderChange}
                                      formType="main"
                                    />
                                </div>
                                <Field
                                  label="Date de naissance"
                                  name="birth_date"
                                  component={DatePicker}
                                  parse={normalizeDates}
                                  format={formatDates}
                                  validate={required}
                                  mandatory={true}
                                  className=" indirect-victim-form pl-4"
                                  wizard={false}
                                  onChange={handleBirthDateChange}
                                />
                            </div>
                            <div className='row align-items-center pb-2' style={{ marginLeft: "0px" }}>
                                <label htmlFor="linkType" className="control-label mb-0">Lien avec la victime directe :</label>
                                <div className='d-flex input-group-sm ml-1'>
                                    <Field
                                      name="linkType"
                                      id="linkType"
                                      component="select"
                                      className="custom-select form-control"
                                      onChange={handleLinkTypeChange}
                                      style={{ fontSize: "0.8125rem", width: "8rem" }}
                                    >
                                        {Object.keys(familyLinkTypes).map((link, key) => <option value={link} key={key} defaultValue={true}>{familyLinkTypes[link][currentGender]}</option>)}
                                    </Field>
                                    {linkType === "other" &&
                                        <Field
                                          name="otherLinkType"
                                          id="comment"
                                          component="input"
                                          style={{ fontSize: "0.8125rem" }}
                                          className="ml-2 form-control"
                                          onChange={handleOtherLinkTypeChange}
                                        />
                                    }
                                </div>
                            </div>

                            <div className='text-right'>
                                <button className="btn btn-sm btn-primary mt-auto" type="submit">Ajouter la victime indirecte</button>
                            </div>
                        </form>
                        {/* <div className='pt-2' style={{minHeight:"50px"}}>
                            <em className="small">Les indices sont actualisés dans Quantum dès leur parution. Pour les indices de prix à la consommation, cette parution est postérieure de quelques jours / semaines par rapport au mois concerné.</em>
                        </div> */}
                    </fieldset>
                </Fade>
            </Modal>
    </div>
  )
}


IndirectVictimBtn.propTypes = {
    handleSubmit: PropTypes.func,
    handleAddVictimForm: PropTypes.func,
    open: PropTypes.bool,
  };

IndirectVictimBtn = reduxForm({
    form: "indirectVictimForm",
    enableReinitialize: true,
  })(IndirectVictimBtn);

export default IndirectVictimBtn;
