import React, { Component } from "react";
import { Link, Navigate } from "react-router-dom";

import { connect } from "react-redux";
import PropTypes from "prop-types";
import { login } from "../../actions/auth";
import LegalFooter from "../layout/LegalFooter";
export class Login extends Component {
  state = {
    email: "",
    password: "",
  };

  static propTypes = {
    login: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool,
  };

  onSubmit = e => {
    e.preventDefault();
    this.props.login(this.state.email, this.state.password);
  };

  onChange = e =>
    this.setState({
      [e.target.name]: e.target.value,
    });

  render() {
    if (this.props.isAuthenticated) {
      return <Navigate to="/" replace />
    }
    const { email, password } = this.state;
    return (
      <div className="col-md-6 m-auto">
        <div className="card card-body mt-5">
          <h2 className="text-center">S'identifier</h2>
          <form onSubmit={this.onSubmit}>
            <div className="form-group">
              <label>Adresse email</label>
              <input
                type="email"
                className="form-control"
                name="email"
                autoFocus
                onChange={this.onChange}
                value={email}
                placeholder="prenom.nom@domaine.fr"
                autoComplete="username"
                required
              />
            </div>
            <div className="form-group">
              <label>Mot de passe</label>
              <input
                type="password"
                className="form-control"
                name="password"
                onChange={this.onChange}
                value={password}
                placeholder="********"
                autoComplete="current-password"
                required
              />
            </div>
            <div className="form-group">
              <button type="submit" className="btn btn-primary">
                S'identifier
              </button>
            </div>
            <p>
              Vous n'avez pas de compte ?{" "}
              <a href="https://buy.stripe.com/28oeYWcYTgJq0N29AH">Créez votre compte ici, vous avez droit à 7 jours gratuits !</a>
            </p>
            <p>
              Mot de passe oublié ?{" "}
              <Link to="/reinitialisation">Réinitialisez votre mot de passe</Link>
            </p>
          </form>
        </div>
        <LegalFooter />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
});
export default connect(
  mapStateToProps,
  { login },
)(Login);
