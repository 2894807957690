import React from "react";
import PropTypes from "prop-types";
import TextareaAutosize from "react-textarea-autosize";

const TextField = props => {
  const { input, readOnly, placeholder } = props;
  return (
    <TextareaAutosize
      {...input}
      className="form-control pt-1"
      placeholder={placeholder}
      minRows={1}
      maxRows={5}
      readOnly={readOnly}
    />
  );
};

TextField.propTypes = {
  input: PropTypes.object,
  readOnly: PropTypes.bool,
  placeholder: PropTypes.string,
};

export default TextField;
