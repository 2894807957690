export const BoxChecked = (item, field, caseValues) => {
    if (!caseValues || !caseValues[item] || Object.keys(caseValues).length === 0) {
        return false
    }
    const rows = caseValues[item];
    const firstLine = rows[0][field];
    return !rows.some((line) => line[field] !== firstLine);
};

export const BoxPInd0Checked = (field, indirectVictim) => {
    if (!indirectVictim || !indirectVictim.length || !Object.prototype.hasOwnProperty.call(indirectVictim[0],field)) {
        return false
    }
    let firstLine = indirectVictim[0][field]
    firstLine += ''
    return !indirectVictim.some((line) => line[field] + '' !== firstLine);
}

export const checkAllValSame = (arr, field) => {
    if (!arr || arr.length === 0) {
        return true;
    }
    const firstFieldVal = arr[0][field] + '';
    return arr.every((obj) => obj[field] + '' === firstFieldVal)
};
