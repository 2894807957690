import React from "react";
import PropTypes from "prop-types";
import { formatDate } from "../../utils";

const CaseMetadata = props => {
    const { date_created, date_modified, created_by, modified_by, is_team_member, variantTitle } = props;
    return (
        <div className="case-metadata mb-1">
            {variantTitle && <b>&nbsp;Version : {variantTitle} - </b>}
            <small>
                {date_created && `Dossier créé le ${formatDate(date_created, 0, true)}${is_team_member ? ` par ${created_by}` : ``}`}
                {date_modified &&
                    date_created.slice(0, 19) !== date_modified.slice(0, 19) &&
                    ` - Modifié le ${formatDate(date_modified, 0, true)}${is_team_member ? ` par ${modified_by}` : ``}`}
            </small>
        </div>
    );
};

CaseMetadata.propTypes = {
    date_created: PropTypes.string,
    created_by: PropTypes.string,
    date_modified: PropTypes.string,
    modified_by: PropTypes.string,
    is_team_member: PropTypes.bool,
};

export default CaseMetadata;
