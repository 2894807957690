/* Stores the tree of damage entries and items and contains, pour each damage entry, details of the data
   which must be taken into account to display and manage this entry.

   Data for each entry:
   - id: unique identifier (used as html id in the display code in ItemTableBody.js)
        - letters for entrues
        - figure for higher level categories
   - parentId: id of the parend category in the tree (0 for highest level categories)
   - abbr: optional, entry abbreviation used for some entries to complete the label
   - label: entry label, used for display purpose
   - items: array (length 1 or 2), lists the items which must be displayed in this entry
        - itemType: one of itemTypes in itemTypes.js
        - multiLine: boolean, if true, a user can add lines in this item
        - lineLabel: str, optional, replacement label for "ligne" (line in French) on the addNewLine button for multiLine items
        - non CascadingLines: boolean, if true, the calculation of each line is insulated from the others
        - computedAmount: boolean, if true, the line amount is calculated based on the values of the fields of this line
        - lossOfOpportunity: boolean, if true, the user can check a box on each line of the item to enable the lossOfOpportunity option
        - annuityCapitalisation: booean, if true, the line amounts of this items can be capitalized
        - disableEntryTPP: boolean, if true, third party payments can't be added at the entry level (they are available for each line separately)
*/

export const directVictimModel = [{
  id: 1,
  parentId: 0,
  label: "Préjudice patrimonial",
},
{
  id: 2,
  parentId: 1,
  label: "Passé",
},
{
  parentId: 2,
  id: "DSA",
  abbr: "DSA",
  label: "Dépenses de santé actuelles",
  disableEntryTPP: true,
  items: [{
    itemType: "DSA",
    multiLine: true,
    nonCascadingLines: true,
  }],
},
{
  parentId: 2,
  id: "FD",
  abbr: "FD",
  label: "Frais divers",
  disableEntryTPP: true,
  items: [{
    itemType: "DSA",
    multiLine: true,
    nonCascadingLines: true,
  }],
},
{
  parentId: 2,
  id: "TPT",
  abbr: "TPP",
  label: "Frais divers - Tierce personne passée",
  items: [{
    itemType: "TPT",
    multiLine: true,
    lineLabel: "une période",
    computedAmount: true,
  }],
},
{
  parentId: 2,
  id: "PGPA",
  abbr: "PGPA",
  label: "Perte de gains professionnels actuels",
  items: [{
    itemType: "PGPA",
    multiLine: true,
    lineLabel: "une période / une dépense",
    computedAmount: true,
  }],
},
{
  parentId: 2,
  id: "AUT1",
  label: "Autre préjudice patrimonial passé",
  disableEntryTPP: true,
  items: [{
    itemType: "AUTPast",
    lineLabel: "un poste de préjudice",
    labelField: "Poste de préjudice",
    multiLine: true,
    nonCascadingLines: true,
    lossOfOpportunity: true,
  }],
},
{
  id: 3,
  parentId: 1,
  label: "Futur",
},
{
  parentId: 3,
  id: "DSF",
  abbr: "DSF",
  label: "Dépenses de santé futures",
  disableEntryTPP: true,
  items: [
    {
      itemType: "DSF",
      multiLine: true,
      annuityCapitalisation: true,
      nonCascadingLines: true,
    },
  ],
},
{
  parentId: 3,
  id: "FLA",
  abbr: "FLA",
  label: "Frais de logement adapté",
  disableEntryTPP: true,
  items: [
    {
      itemType: "DSF",
      multiLine: true,
      annuityCapitalisation: true,
      nonCascadingLines: true,
    },
  ],
},
{
  parentId: 3,
  id: "FVA",
  abbr: "FVA",
  label: "Frais de véhicule adapté",
  disableEntryTPP: true,
  items: [
    {
      itemType: "DSF",
      multiLine: true,
      annuityCapitalisation: true,
      nonCascadingLines: true,
    },
  ],
},
{
  parentId: 3,
  id: "TPF",
  abbr: "TPF",
  label: "Tierce personne future",
  items: [{
    itemType: "TPT",
    label: "Arrérages échus",
    multiLine: true,
    lineLabel: "une période",
    computedAmount: true,
  },
  {
    itemType: "TPF",
    label: "Arrérages à échoir",
    disableEntryTPP: true,
    annuityCapitalisation: true,
    nonCascadingLines: true,
    computedAmount: true,
  },
  ],
},
{
  parentId: 3,
  id: "PGPF",
  abbr: "PGPF",
  label: "Perte de gains professionnels futurs",

  items: [{
    itemType: "PGPA",
    label: "Arrérages échus",
    multiLine: true,
    lineLabel: "une période",
    computedAmount: true,
  },
  {
    itemType: "PGPFProj",
    label: "Arrérages à échoir",
    lineLabel: "une perte de gains professionnels",
    disableEntryTPP: true,
    computedAmount: true,
    annuityCapitalisation: true,
  },
  ],
},
{
  parentId: 3,
  id: "IP",
  label: "Incidence professionnelle",
  items: [{
    itemType: "IP",
    multiLine: true,
    lossOfOpportunity: true,
    lineLabel: "une composante",
  }],
},
{
  parentId: 3,
  id: "PSUF",
  abbr: "PSUF",
  label: "Préjudice scolaire,",
  additionalLabel: " universitaire ou de formation",
  items: [{
    itemType: "IP",
    multiLine: true,
    lossOfOpportunity: true,
    lineLabel: "un préjudice",
  }],
},
{
  parentId: 3,
  id: "AUT2",
  label: "Autre préjudice patrimonial futur",
  disableEntryTPP: true,
  items: [{
    itemType: "AUTFuture",
    multiLine: true,
    lineLabel: "un poste de préjudice",
    labelField: "Poste de préjudice",
    nonCascadingLines: true,
    lossOfOpportunity: true,
  }],
},
{
  parentId: 3,
  id: "AUT2Cap",
  label: "Autre préjudice patrimonial futur",
  additionalLabel: " (capitalisation et rente)",
  disableEntryTPP: true,
  items: [{
    itemType: "AUTFuture",
    multiLine: true,
    lineLabel: "un poste de préjudice",
    labelField: "Poste de préjudice",
    annuityCapitalisation: true,
    nonCascadingLines: true,
    lossOfOpportunity: true,
  }],
},
{
  id: 4,
  parentId: 0,
  label: "Préjudice extra-patrimonial",
},
{
  id: 5,
  parentId: 4,
  label: "Passé",
},
{
  parentId: 5,
  id: "SE",
  abbr: "SE",
  label: "Souffrances endurées",
  items: [{
    itemType: "SE",
  }],
},
{
  parentId: 5,
  id: "PET",
  abbr: "PET",
  label: "Préjudice esthétique temporaire",
  items: [{
    itemType: "SE",
  }],
},
{
  parentId: 5,
  id: "DFT",
  abbr: "DFT",
  label: "Déficit fonctionnel temporaire",
  items: [{
    itemType: "DFT",
    multiLine: true,
    lineLabel: "une période",
    computedAmount: true,
  }],
},
{
  parentId: 5,
  id: "AUT3",
  label: "Autre préjudice extra patrimonial passé",
  disableEntryTPP: true,
  items: [{
    itemType: "AUTPast",
    multiLine: true,
    lineLabel: "un poste de préjudice",
    labelField: "Poste de préjudice",
    nonCascadingLines: true,
    lossOfOpportunity: true,
  }],
},
{
  id: 6,
  parentId: 4,
  label: "Futur",
},
{
  parentId: 6,
  id: "DFP",
  abbr: "DFP",
  label: "Déficit fonctionnel permanent",
  items: [{
    itemType: "DFP",
    computedAmount: true,
    multiLine: true,
    lineLabel: "un déficit",
  }],
},
{
  parentId: 6,
  id: "PA",
  abbr: "PA",
  label: "Préjudice d'agrément",
  items: [{
    itemType: "PA",
  }],
},
{
  parentId: 6,
  id: "PEP",
  abbr: "PEP",
  label: "Préjudice esthétique permanent",
  items: [{
    itemType: "SE",
  }],
},
{
  parentId: 6,
  id: "PS",
  abbr: "PS",
  label: "Préjudice sexuel",
  items: [{
    itemType: "PA",
  }],
},
{
  parentId: 6,
  id: "PE",
  abbr: "PE",
  label: "Préjudice d'établissement",
  items: [{
    itemType: "PA",
  }],
},
{
  parentId: 6,
  id: "PEX",
  label: "Préjudice exceptionnel",
  items: [{
    itemType: "PA",
  }],
},
{
  parentId: 6,
  id: "AUT4",
  label: "Autre préjudice extra patrimonial futur",
  disableEntryTPP: true,
  items: [{
    itemType: "AUTFuture",
    multiLine: true,
    labelField: "Poste de préjudice",
    lineLabel: "un poste de préjudice",
    nonCascadingLines: true,
    lossOfOpportunity: true,
  }],
},
{
  parentId: 0,
  id: "DNAPRDI",
  label: "Divers",
  additionalLabel: " non affecté par la réduction du droit à indemnisation",
  additionalLabel2: "art. 700, dépens...",
  disableEntryTPP: true,
  items: [{
    itemType: "DIV",
    multiLine: true,
    lineLabel: "un préjudice",
    labelField: "Préjudice",
    nonCascadingLines: true,
  }],
},
{
  parentId: 0,
  id: "PROV",
  label: "Montant(s) à déduire",
  additionalLabel2: "non soumis à recours : provisions...",
  disableEntryTPP: true,
  items: [{
    itemType: "DIVDate",
    multiLine: true,
    amountLabel: "Montant à déduire",
    lineLabel: "un montant",
  }],
},
];


export const indirectVictimModel = [{
  id: 1,
  parentId: 0,
  label: "Préjudice de la victime indirecte",
},
{
  parentId: 1,
  id: "FD",
  abbr: "FD",
  label: "Frais divers",
  disableEntryTPP: true,
  items: [{
    itemType: "DSA",
    multiLine: true,
    nonCascadingLines: true,
  }],
},
{
  parentId: 1,
  id: "PAff",
  label: "Préjudice d'affection",
  items: [{
    itemType: "PA",
  }],
},
{
  parentId: 1,
  id: "PRev",
  label: "Perte de revenus des proches",
  disableEntryTPP: true,
  items: [{
    itemType: "PRev",
    lineLabel: "une perte de revenus",
    multiLine: true,
  }],
},
{
  parentId: 1,
  id: "PAcc",
  label: "Préjudice d'accompagnement",
  disableEntryTPP: true,
  items: [{
    itemType: "PAcc",
    multiLine: true,
    nonCascadingLines: true,
    lineLabel: "un préjudice",
  }],
},
{
  parentId: 1,
  id: "PInd",
  label: "Perte en industrie",
  items: [{
    itemType: "TPT",
    label: "Arrérages échus",
    multiLine: true,
    lineLabel: "une période",
    computedAmount: true,
    },
    {
    itemType: "TPF",
    label: "Arrérages à échoir",
    annuityCapitalisation: true,
    labelField: "Perte en industrie",
    lineLabel: "une perte en industrie",
    nonCascadingLines: true,
    computedAmount: true,
  }],
},
{
  parentId: 1,
  id: "AUT",
  label: "Autre poste de préjudice sans capitalisation",
  disableEntryTPP: true,
  items: [{
    itemType: "AUTPast",
    multiLine: true,
    lineLabel: "un poste de préjudice",
    labelField: "Poste de préjudice",
    nonCascadingLines: true,
    lossOfOpportunity: true,
  }],
},
{
  parentId: 1,
  id: "AUTCap",
  label: "Autre poste de préjudice à capitalisation",
  disableEntryTPP: true,
  items: [{
    itemType: "AUTFuture",
    annuityCapitalisation: true,
    multiLine: true,
    labelField: "Poste de préjudice",
    lineLabel: "un poste de préjudice",
    nonCascadingLines: true,
    lossOfOpportunity: true,
  }],
},
{
  parentId: 0,
  id: "DNAPRDI",
  label: "Divers",
  additionalLabel: " non affecté par la réduction du droit à indemnisation",
  additionalLabel2: "art. 700, dépens...",
  disableEntryTPP: true,
  items: [{
    itemType: "DIV",
    multiLine: true,
    lineLabel: "un préjudice",
    labelField: "Préjudice",
    nonCascadingLines: true,
  }],
},
{
  parentId: 0,
  id: "PROV",
  label: "Montant(s) à déduire",
  additionalLabel2: "non soumis à recours : provisions...",
  disableEntryTPP: true,
  items: [{
    itemType: "DIVDate",
    multiLine: true,
    amountLabel: "Montant à déduire",
    lineLabel: "un montant",
  }],
},
];
