import React, { useState } from "react";
import PropTypes from "prop-types";
// import { Transition } from "react-transition-group";

import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import Scrollspy from "react-scrollspy"; //Scrollspy triggers React warnings
import { connect } from "react-redux";
import { change, formValueSelector, getFormValues } from "redux-form";


let SideMenu = props => {
  const { entries, caseValues, dispatch, currentVictim } = props;
  const [isMenuOpen, setMenuState] = useState(true);
  // const transitionDuration = 1000;
  
  const openEntry = entryId => {
    let fieldName = "_meta[" + entryId + "].minimized";
    dispatch(
      change(
        "caseDataForm",
        fieldName,
        false,
      ),
    );
  }

  let buildEntryList = entries => {
    let entryList = [];
    entries.forEach(entry => entryList.push(entry.id.toString()));
    return entryList;
  };

  let buildMenuEntry = entry => {
    let menuEntry = {};
    let completed = false;
    menuEntry.label = entry.label;
    menuEntry.additionalLabel = "";
    if (entry.additionalLabel || entry.abbr)
      menuEntry.additionalLabel = (
        <span className="abbreviation">
          {entry.additionalLabel && entry.additionalLabel}
          {entry.abbr && ` (${entry.abbr})`}
        </span>
      );
    if (isNaN(entry.id)) {
      menuEntry.label = (
        <a href={`#${entry.id}`} className="nav-link" onClick={() => openEntry(entry.id)}>
          {menuEntry.label} {menuEntry.additionalLabel}
        </a>
      );
    }

    if (entry.parentId === 0) menuEntry.className = " l1";
    else if (isNaN(entry.id)) menuEntry.className = " l3";
    else menuEntry.className = " l2";
    if (entry.items) {
      entry.items.forEach((item, i) => {
        if (
          caseValues &&
          caseValues._meta &&
          caseValues._meta[entry.id] &&
          caseValues._meta[entry.id].items &&
          caseValues._meta[entry.id].items[i] &&
          (caseValues._meta[entry.id].items[i].damageTotal ||
            caseValues._meta[entry.id].items[i].hasTPP)
        )
          completed = true;
      });
      menuEntry.className += ` font-weight-${completed ? "bold" : "light"}`;
    }
    return menuEntry;
  };

  return (
    <div className={`${isMenuOpen ? " sidebarOn" : " sidebarOff"}`}>
      <nav
        className={`navbar position-fixed flex-column justify-content-start pl-0${isMenuOpen ? "" : " side-navbar"
          }`}
      >
        <div className="container-fluid p-0 pb-1">
          {isMenuOpen && <h2 className="menu-title mb-0">Postes de préjudice</h2>}
          <div className="float-right" title={isMenuOpen ? "Masquer le menu" : "Afficher le menu"}>
            <button type="button" className="btn btn-sm" onClick={() => setMenuState(!isMenuOpen)}>
              {isMenuOpen ? <ArrowBackIosIcon /> : <ArrowForwardIosIcon />}
            </button>
          </div>
        </div>

        {isMenuOpen && (
          <div className="menu">
            <Scrollspy
              items={buildEntryList(entries)}
              currentClassName="mark"
              className="navbar-nav"
            >
              {entries &&
                entries.map(entry => (
                  <li key={entry.id} className={"nav-item" + buildMenuEntry(entry).className}>
                    {buildMenuEntry(entry).label}
                  </li>
                ))}
            </Scrollspy>
            <div className="footer-spacer"></div>
          </div>
        )}
      </nav>
    </div>
  );
};

const dataSelector = formValueSelector("caseDataForm");

const mapStateToProps = state => {
  let entries = [];
  let currentVictim = dataSelector(state, 'currentVictim')
  const json_data = state.cases.case.json_data;
  let caseValues = getFormValues("caseDataForm")(state);

  if (!currentVictim && json_data)
    entries = json_data.caseDataForm;
  else if (caseValues && caseValues.indirectVictims && caseValues.indirectVictims[currentVictim-1])
    {
      entries = caseValues.indirectVictims[currentVictim-1].caseDataForm;
      caseValues = caseValues.indirectVictims[currentVictim-1].caseData;
    }

  return { entries, currentVictim, caseValues };
};

SideMenu.propTypes = {
  entries: PropTypes.array,
  caseValues: PropTypes.object,
};

export default connect(mapStateToProps)(SideMenu);
