import React from "react";
import PropTypes from "prop-types";
import EuroIcon from "@material-ui/icons/Euro";
import TpIcon from "../../layout/icons/TpIcon"

function BtnAddEntryTPPLine({ addTPPLine }) {
  return (
    <div className="float-left">
      <button
        type="button"
        className="btn btn-outline-warning btn-sm"
        onClick={() => addTPPLine()}
        title="Ajouter un paiement de tiers soumis à recours sur les arrérages échus"
      >
        <TpIcon />
        &nbsp; Ajouter un paiement de tiers
      </button>
    </div>
  );
}

BtnAddEntryTPPLine.propTypes = {
  addTPPLine: PropTypes.func.isRequired,
};

export default BtnAddEntryTPPLine;
