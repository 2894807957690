import { useEffect, useRef } from "react";
export function useOnMountUnsafe(effect) {
    var initialized = useRef(false);
    useEffect(function () {
        if (!initialized.current) {
            initialized.current = true;
            effect();
        }
    }, []);
}
