var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { formValueSelector, getFormValues, submit } from "redux-form";
import SaveIcon from "@material-ui/icons/Save";
import LockIcon from "@material-ui/icons/Lock";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import NumberFormat from "react-number-format";
import MergeButton from "./MergeButton";
import { loadCaseVariants, patchCaseVariant } from "../../../../actions/cases";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { getEntrySummaryCalculated } from "../calculations_single";
function CaseVersionLockDialog(props) {
    return React.createElement(Dialog, { open: props.open, onClose: props.onClose, "aria-labelledby": "alert-dialog-title", "aria-describedby": "alert-dialog-description" },
        React.createElement(DialogTitle, { id: "alert-dialog-title" }, props.isLocked
            ? "D\u00E9verrouiller la version ".concat(props.variantTitle, " ?")
            : "Verrouiller la version ".concat(props.variantTitle, " ?")),
        React.createElement(DialogContent, null,
            React.createElement(DialogContentText, { id: "alert-dialog-description" },
                "\u00CAtes-vous s\u00FBr de vouloir verrouiller la version ",
                props.variantTitle,
                " du dossier ",
                props.caseTitle,
                " ?"),
            React.createElement(DialogContentText, { id: "alert-dialog-description-2" },
                "Ce verrouillage est irr\u00E9versible : vous pourrez continuer \u00E0 consulter et \u00E0 exporter cette version mais vous ne pourrez plus la modifier ",
                React.createElement("strong", null, "(y compris son intitul\u00E9 et sa description)"),
                ", et vous ne pourrez pas la d\u00E9verrouiller.")),
        React.createElement(DialogActions, null,
            React.createElement(Button, { onClick: props.onClose, color: "primary", size: "small" }, "Annuler"),
            React.createElement(Button, { onClick: props.onLock, variant: "contained", color: "secondary", size: "small", autoFocus: true, startIcon: React.createElement(LockIcon, null) }, "Verrouiller")));
}
function CaseFooterLockButton(props) {
    var _this = this;
    var caseId = props.caseId, caseItem = props.caseItem, dispatch = props.dispatch, isLocked = props.isLocked;
    var _a = useState(false), lockDialogOpen = _a[0], setLockDialogOpen = _a[1];
    var onDialogLock = function () { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, , 3, 4]);
                    // if (isLocked) {
                    //     await dispatch(patchCaseVariant(caseId.toString(),caseItem.variant , {locked: false}))
                    // } else
                    // {
                    return [4 /*yield*/, dispatch(patchCaseVariant(caseId.toString(), caseItem.variant, { locked: true }))
                        // }
                        // TODO reload case data or just patch the current data with result
                        // TODO Maybe use the isLocked from the caseVariants and not the caseItem.variant_locked
                    ];
                case 1:
                    // if (isLocked) {
                    //     await dispatch(patchCaseVariant(caseId.toString(),caseItem.variant , {locked: false}))
                    // } else
                    // {
                    _a.sent();
                    // }
                    // TODO reload case data or just patch the current data with result
                    // TODO Maybe use the isLocked from the caseVariants and not the caseItem.variant_locked
                    return [4 /*yield*/, dispatch(loadCaseVariants(caseItem))];
                case 2:
                    // }
                    // TODO reload case data or just patch the current data with result
                    // TODO Maybe use the isLocked from the caseVariants and not the caseItem.variant_locked
                    _a.sent();
                    return [3 /*break*/, 4];
                case 3:
                    setLockDialogOpen(false);
                    return [7 /*endfinally*/];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    return React.createElement(React.Fragment, null,
        React.createElement("button", { className: "btn btn-primary ml-auto", disabled: isLocked, onClick: function () { return setLockDialogOpen(true); }, title: "Verrouiller la version" }, isLocked ?
            React.createElement(LockIcon, { fontSize: "small", className: "saveIcon" }) :
            React.createElement(LockOpenIcon, { fontSize: "small", className: "saveIcon" })),
        React.createElement(CaseVersionLockDialog, { open: lockDialogOpen, isLocked: isLocked, caseTitle: caseItem.title, variantTitle: caseItem.variant_title || '', variantDescription: caseItem.variant_description || '', onClose: function () { return setLockDialogOpen(false); }, onLock: onDialogLock }));
}
var CaseFooter = function (props) {
    var entries = props.entries, caseValues = props.caseValues, dispatch = props.dispatch, caseId = props.caseId, token = props.token;
    var victimEntries = entries;
    var victimValues = caseValues || {};
    var victimId = victimValues.currentVictim;
    if (victimId && caseValues.indirectVictims && caseValues.indirectVictims.length >= victimId && caseValues.indirectVictims[victimId - 1]) {
        victimValues = caseValues.indirectVictims[victimId - 1].caseData;
        victimEntries = caseValues.indirectVictims[victimId - 1].caseDataForm;
    }
    var _meta = victimValues._meta || {};
    var victimSummaryData = _meta.victimSummary || {};
    var _a = useState(false), detailedView = _a[0], setDetailedView = _a[1];
    return (React.createElement("div", { className: "fixed-bottom text-white footer d-flex", style: { gap: 14 } },
        React.createElement("div", { className: "ml-5 pl-5 p-2" },
            React.createElement("table", null,
                React.createElement("thead", null,
                    React.createElement("tr", null,
                        React.createElement("th", { colSpan: 3 },
                            React.createElement("h2", { className: "mb-0" },
                                "Synth\u00E8se du dossier",
                                " ",
                                victimValues && victimValues._meta && Object.keys(victimValues._meta).length > 0 && (React.createElement("span", { onClick: function () { return setDetailedView(!detailedView); }, title: "".concat(detailedView ? "Masquer" : "Afficher", " le d\u00E9tail par poste") }, detailedView ? React.createElement(KeyboardArrowDownIcon, null) : React.createElement(KeyboardArrowUpIcon, null)))))),
                    (victimSummaryData.annuityTotal > 0) && (React.createElement("tr", null,
                        React.createElement("th", null),
                        React.createElement("th", { className: "text-center align-bottom" }, "en capital"),
                        React.createElement("th", { className: "text-center align-bottom" },
                            "sous forme de rente",
                            React.createElement("br", null),
                            React.createElement("small", null, "(montant annualis\u00E9)"))))),
                detailedView &&
                    victimValues &&
                    victimValues._meta &&
                    Object.keys(victimValues._meta).length > 0 && (React.createElement("tbody", null, victimEntries.map(function (entry, i) {
                    return (victimId && !isNaN(entry.id) && entry.parentId === 0 && (React.createElement("tr", { key: i },
                        React.createElement("td", { className: "l1" }, entry.label),
                        React.createElement("td", null),
                        React.createElement("td", null)))) ||
                        (!isNaN(entry.id) && entry.parentId !== 0 && (React.createElement("tr", { key: i },
                            React.createElement("td", { className: "l1" },
                                victimEntries.filter(function (e) { return e.id === entry.parentId; }).map(function (e) { return e.label; }) + " ",
                                entry.label.toLowerCase()),
                            React.createElement("td", null),
                            React.createElement("td", null)))) ||
                        (victimValues._meta[entry.id] &&
                            victimValues._meta[entry.id].items &&
                            (getEntrySummaryCalculated(victimValues, entry.id).total > 0 ||
                                getEntrySummaryCalculated(victimValues, entry.id).annuityTotal > 0 ||
                                getEntrySummaryCalculated(victimValues, entry.id).hasTPP) && (React.createElement("tr", { key: i },
                            React.createElement("td", { className: entry.parentId !== 0 ? "l3" : "l1" },
                                entry.label,
                                entry.additionalLabel),
                            React.createElement("td", { className: "text-right col-amount".concat(entry.parentId !== 0 ? " l3" : "l1") },
                                entry.id.indexOf("PROV") === 0 && "-",
                                React.createElement(NumberFormat, { displayType: "text", thousandSeparator: " ", decimalSeparator: ",", decimalScale: 2, fixedDecimalScale: true, type: "text", value: entry.id === "PGPF" || entry.id === "IP"
                                        ? Math.max(getEntrySummaryCalculated(victimValues, entry.id).total, 0)
                                        : Math.max(getEntrySummaryCalculated(victimValues, entry.id).total, 0), suffix: " \u20AC" })),
                            React.createElement("td", { className: "text-right col-amount".concat(entry.parentId !== 0 ? " l3" : "") }, getEntrySummaryCalculated(victimValues, entry.id).annuityTotal >
                                0 && (React.createElement(NumberFormat, { displayType: "text", thousandSeparator: " ", decimalSeparator: ",", decimalScale: 2, fixedDecimalScale: true, type: "text", value: getEntrySummaryCalculated(victimValues, entry.id).annuityTotal, suffix: " \u20AC" }))))));
                }))),
                React.createElement("tfoot", null,
                    React.createElement("tr", null,
                        React.createElement("th", { className: "align-top" }, "Total \u00E0 percevoir par la victime"),
                        React.createElement("td", { className: "text-right col-amount align-top" },
                            React.createElement(NumberFormat, { displayType: "text", thousandSeparator: " ", decimalSeparator: ",", decimalScale: 2, fixedDecimalScale: true, type: "text", value: victimSummaryData.total, suffix: " \u20AC" }),
                            victimSummaryData.total < 0 && victimSummaryData.annuityTotal > 0 && (React.createElement(Fragment, null,
                                React.createElement("br", null),
                                React.createElement("small", null, "(montant \u00E0 d\u00E9duire de la rente vers\u00E9e)")))),
                        React.createElement("td", { className: "text-right col-amount align-top" }, victimSummaryData.annuityTotal > 0 && (React.createElement(NumberFormat, { displayType: "text", thousandSeparator: " ", decimalSeparator: ",", decimalScale: 2, fixedDecimalScale: true, type: "text", value: victimSummaryData.annuityTotal, suffix: " \u20AC" }))))))),
        React.createElement("div", { style: { display: 'flex', gap: 20, flexGrow: 1, justifyContent: 'flex-end', alignItems: 'center' } },
            React.createElement("div", null,
                React.createElement("button", { type: "submit", className: "btn btn-primary ml-auto", disabled: props.case.variant_locked, onClick: function () {
                        dispatch(submit("caseDataForm"));
                    }, title: "Enregistrer les modifications" },
                    React.createElement(SaveIcon, { fontSize: "large", className: "saveIcon" }))),
            React.createElement("div", null,
                React.createElement(CaseFooterLockButton, { caseItem: props.case, isLocked: props.case.variant_locked, caseId: caseId, dispatch: dispatch }))),
        React.createElement(MergeButton, { caseId: caseId, token: token, victimId: victimId })));
};
var victimSelector = formValueSelector("victim");
var mapStateToProps = function (state) {
    var entries = [];
    var victim = {};
    var caseValues = {};
    var token = '';
    if (state.auth && state.auth.token)
        token = state.auth.token;
    if (state.cases && state.cases.case && state.cases.case.json_data) {
        entries = state.cases.case.json_data.caseDataForm;
        victim.rateOfCompensationEntitlement = victimSelector(state, "rateOfCompensationEntitlement");
        victim.disableCascade = victimSelector(state, "disableCascade");
        victim.birthDate = victimSelector(state, "birth_date");
        victim.gender = victimSelector(state, "victimGender");
        caseValues = getFormValues("caseDataForm")(state) || {};
    }
    return { entries: entries, victim: victim, caseValues: caseValues, token: token, case: state.cases.case };
};
CaseFooter.propTypes = {
    entries: PropTypes.array,
    victim: PropTypes.object,
    caseValues: PropTypes.object,
    dispatch: PropTypes.func,
    caseId: PropTypes.number
};
export default connect(mapStateToProps)(CaseFooter);
