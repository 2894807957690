import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { compensationScales } from "../compensation_scales/constants";
import FullWidthTabs from "./Tabs"

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: 'none',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        textAlign: 'center',
    },

}));

export default function DisplayCompensationScale(props) {
    const { entryId, entryTitle, victim, entryValues } = props;
    const compensationScale = compensationScales[entryId];
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    let damageRate;
    if (entryValues && entryValues.length > 0 && entryValues[0].damageRate) {
      damageRate = Math.trunc(parseFloat(entryValues[0].damageRate.toString().replace(",",".")));
    }

    return (
        <div className="ml-2" onClick={((e) => e.stopPropagation())}>
            <button type="button" className="btn btn-link btn-sm" onClick={handleOpen}>
                Afficher les {compensationScale.link[0]}
            </button>

            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              className={classes.modal}
              open={open}
              onClose={handleClose}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={classes.paper}>
                        <h2 id="transition-modal-title">{compensationScale.link[0].charAt(0).toUpperCase() + compensationScale.link[0].slice(1)} {compensationScale.link[1]} {entryTitle.charAt(0).toLowerCase() + entryTitle.slice(1)}</h2>
                        <FullWidthTabs tables={compensationScale.tables} victim={victim} damageRate={damageRate} />
                    </div>
                </Fade>
            </Modal>
        </div>
    );
}
