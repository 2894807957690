import { directVictimModel, indirectVictimModel } from "./dataModels";
import {
    capLineResponsiblePartyAmount,
    capLineTPPRecourse,
    capLineVictimAmount,
    dsfLineCapitalisedAmount,
    getCascadedTotal,
    getDsfFutureAnnualDamage,
    getDsfFutureArrSummary,
    getDsfLineSummary,
    getDsfPastArrSummary,
    getEntrySummary,
    getLineAmount,
    getPastTppCascadable,
    getWorkIncomeTotal,
    lineAmountAfterLossOfOpportunity,
    lineCapitalisedAmount,
    lineResponsiblePartyAmount, lineThirdPartyPayersAmount,
    lineVictimAmount,
    lineVictimDiscountedAmount,
    subEntryTotal,
    victimSummary,
} from "./calculations";

import { getHouseholdIncomesList, getPRevEntryTotal } from "./case_data_form/household_income/calculations";


export function getEntryNameAndIndexFromEntryName(entryName) {
    const entryNameRegex = /(\w+)(\d)/
    const matches = entryName.match(entryNameRegex)
    if (matches == null) {
        return {}
    }
    return { name: matches[1], index: matches[2] }
}

export function getMetaValueFromEntryName(values, entryName) {
    const { name, index } = getEntryNameAndIndexFromEntryName(entryName)

    if (name === undefined || index === undefined) {
        return null
    }

    if (values._meta == null || values._meta[name] == null || values._meta[name].items == null) {
        return null
    }
    return values._meta[name].items[index]
}

function getLineCalculationFieldValue(values, fieldsName, index, attributeName) {
    const metaValue = getMetaValueFromEntryName(values, fieldsName)
    if (!metaValue) {
        return 0
    }

    if (metaValue.lineCalculatations == null || metaValue.lineCalculatations.length <= index || metaValue.lineCalculatations[index] == null || metaValue.lineCalculatations[index][attributeName] == null) {
        return 0
    }

    return metaValue.lineCalculatations[index][attributeName]
}

export function getLineVictimAmountCalculated(values, fieldsName, index) {
    return getLineCalculationFieldValue(values, fieldsName, index, 'lineVictimAmountCalculated')
}

export function getWorkIncomeTotalCalculated(values, fieldsName, index) {
    return getLineCalculationFieldValue(values, fieldsName, index, 'workIncomeTotalCalculated')
}

export function getLineVictimDiscountedAmountCalculated(values, fieldsName, index) {
    return getLineCalculationFieldValue(values, fieldsName, index, 'lineVictimDiscountedAmountCalculated')
}

export function getLineAmountAfterLossOfOpportunityCalculated(values, fieldsName, index) {
    return getLineCalculationFieldValue(values, fieldsName, index, 'lineAmountAfterLossOfOpportunityCalculated')
}

export function getCapLineTPPRecourseCalculated(values, fieldsName, index) {
    return getLineCalculationFieldValue(values, fieldsName, index, 'capLineTPPRecourseCalculated')
}

export function getCapLineResponsiblePartyAmountCalculated(values, fieldsName, index) {
    return getLineCalculationFieldValue(values, fieldsName, index, 'capLineResponsiblePartyAmountCalculated')
}

export function getCapLineVictimAmountCalculated(values, fieldsName, index) {
    return getLineCalculationFieldValue(values, fieldsName, index, 'capLineVictimAmountCalculated')
}

export function getLineAmountCalculated(values, fieldsName, index) {
    return getLineCalculationFieldValue(values, fieldsName, index, 'lineAmountCalculated')
}

export function getLineResponsiblePartyAmountCalculated(values, fieldsName, index) {
    return getLineCalculationFieldValue(values, fieldsName, index, 'lineResponsiblePartyAmountCalculated')
}


export function getLineThirdPartyPayersAmountCalculated(values, fieldsName, index) {
    return getLineCalculationFieldValue(values, fieldsName, index, 'lineThirdPartyPayersAmountCalculated')
}

export function getDSFLineSummaryCalculated(values, fieldsName, index) {
    return getLineCalculationFieldValue(values, fieldsName, index, 'dsfLineSummaryCalculated')
}

export function getDSFLineCapitalisedAmountCalculated(values, fieldsName, index) {
    return getLineCalculationFieldValue(values, fieldsName, index, 'dsfLineCapitalisedAmountCalculated')
}

export function getDsfFutureAnnualDamageCalculated(values, fieldsName, index) {
    return getLineCalculationFieldValue(values, fieldsName, index, 'dsfFutureAnnualDamageCalculated')
}

export function getDsfFutureArrSummaryCalculated(values, fieldsName, index) {
    return getLineCalculationFieldValue(values, fieldsName, index, 'dsfFutureArrSummaryCalculated')
}

export function getDSFPastArrSummaryCalculated(values, fieldsName, index) {
    return getLineCalculationFieldValue(values, fieldsName, index, 'dsfPastArrSummaryCalculated')
}

export function getPastTppCascadableCalculated(values, fieldsName, index) {
    return getLineCalculationFieldValue(values, fieldsName, index, 'pastTppCascadableCalculated')
}

export function getLineCapitalisedAmountCalculated(values, fieldsName, index) {
    return getLineCalculationFieldValue(values, fieldsName, index, 'lineCapitalisedAmountCalculated')
}

export function getEntrySummaryCalculated(values, fieldsName) {
    if (values._meta == null || values._meta[fieldsName] == null || values._meta[fieldsName].entrySummaryCalculated == null) {
        return 0
    }

    return values._meta[fieldsName].entrySummaryCalculated
}


export function calcSingle(caseValues, victim, entries) {
    if (Object.keys(caseValues).length === 0) {
        return caseValues
    }

    const clonedValues = JSON.parse(JSON.stringify(caseValues))
    clonedValues._meta = clonedValues._meta || {}

    for (const value of directVictimModel) {
        if (value.items == null || value.items.length === 0) {
            continue
        }

        for (let i = 0; i < value.items.length; i++) {
            const item = value.items[i]
            const itemId = value.id // DSA, FD, IP, PA, PE PS
            const entryName = itemId + i // DSA0, FD0, IP0, PA0, PE0, PS0


            const calcVal = subEntryTotal(clonedValues, victim, entryName, item)
            clonedValues._meta[itemId] = clonedValues._meta[itemId] || {}
            clonedValues._meta[itemId].items = clonedValues._meta[itemId].items || []
            clonedValues._meta[itemId].items[i] = calcVal

            clonedValues._meta[itemId].items[i].cascadedTotal = getCascadedTotal(clonedValues, victim, entryName)

            if (clonedValues[entryName] != null) {
                clonedValues._meta[itemId].items[i].lineCalculatations = clonedValues._meta[itemId].items[i].lineCalculatations || {}

                for (let entryIndex = 0; entryIndex < clonedValues[entryName].length; entryIndex++) {
                    clonedValues._meta[itemId].items[i].lineCalculatations[entryIndex] = clonedValues._meta[itemId].items[i].lineCalculatations[entryIndex] || {}
                    clonedValues._meta[itemId].items[i].lineCalculatations[entryIndex].lineVictimAmountCalculated = lineVictimAmount(clonedValues, entryName, entryIndex, item, victim);
                    clonedValues._meta[itemId].items[i].lineCalculatations[entryIndex].lineAmountCalculated = getLineAmount(clonedValues, entryName, entryIndex, item);
                    clonedValues._meta[itemId].items[i].lineCalculatations[entryIndex].lineResponsiblePartyAmountCalculated = lineResponsiblePartyAmount(clonedValues, entryName, entryIndex, item, victim);
                    clonedValues._meta[itemId].items[i].lineCalculatations[entryIndex].lineThirdPartyPayersAmountCalculated = lineThirdPartyPayersAmount(clonedValues, entryName, entryIndex, item, victim);
                    clonedValues._meta[itemId].items[i].lineCalculatations[entryIndex].dsfLineSummaryCalculated = getDsfLineSummary(clonedValues, entryName, entryIndex, item, victim);

                    if (clonedValues[entryName][entryIndex] && clonedValues[entryName][entryIndex] && clonedValues[entryName][entryIndex].workIncome) {
                        clonedValues._meta[itemId].items[i].lineCalculatations[entryIndex].workIncomeTotalCalculated = getWorkIncomeTotal(clonedValues[entryName][entryIndex])
                    }

                    clonedValues._meta[itemId].items[i].lineCalculatations[entryIndex].lineCapitalisedAmountCalculated = lineCapitalisedAmount(clonedValues, entryName, entryIndex, item, victim);
                    clonedValues._meta[itemId].items[i].lineCalculatations[entryIndex].lineVictimDiscountedAmountCalculated = lineVictimDiscountedAmount(clonedValues, entryName, entryIndex, item, victim);
                    clonedValues._meta[itemId].items[i].lineCalculatations[entryIndex].lineAmountAfterLossOfOpportunityCalculated = lineAmountAfterLossOfOpportunity(clonedValues, entryName, entryIndex, item); // arrerageType, arrerageIndex
                    clonedValues._meta[itemId].items[i].lineCalculatations[entryIndex].capLineTPPRecourseCalculated = capLineTPPRecourse(clonedValues, entryName, entryIndex, item, victim);
                    clonedValues._meta[itemId].items[i].lineCalculatations[entryIndex].capLineVictimAmountCalculated = capLineVictimAmount(clonedValues, entryName, entryIndex, item, victim);
                    clonedValues._meta[itemId].items[i].lineCalculatations[entryIndex].capLineResponsiblePartyAmountCalculated = capLineResponsiblePartyAmount(clonedValues, entryName, entryIndex, item, victim);

                    clonedValues._meta[itemId].items[i].lineCalculatations[entryIndex].pastTppCascadableCalculated = getPastTppCascadable(clonedValues, entryName, entryIndex, item, victim);


                    if (clonedValues[entryName][i] && clonedValues[entryName][i].arr && clonedValues[entryName][i].arr.future) {
                        clonedValues._meta[itemId].items[i].lineCalculatations[entryIndex].dsfLineCapitalisedAmountCalculated = dsfLineCapitalisedAmount(clonedValues, entryName, entryIndex, item, victim);
                        clonedValues._meta[itemId].items[i].lineCalculatations[entryIndex].dsfFutureArrSummaryCalculated = getDsfFutureArrSummary(clonedValues, entryName, entryIndex, item, victim);

                        clonedValues._meta[itemId].items[i].lineCalculatations[entryIndex].dsfFutureAnnualDamageCalculated = getDsfFutureAnnualDamage(clonedValues, entryName, entryIndex, item, victim);
                    }
                    if (clonedValues[entryName][i] && clonedValues[entryName][i].arr && clonedValues[entryName][i].arr.past) {
                        clonedValues._meta[itemId].items[i].lineCalculatations[entryIndex].dsfPastArrSummaryCalculated = getDsfPastArrSummary(clonedValues, entryName, entryIndex, item, victim);
                    }
                }
            }
            const entryItems = clonedValues._meta[itemId].items
            clonedValues._meta[itemId].entrySummaryCalculated = getEntrySummary(entryItems, itemId);
        }
    }

    const victimSummaryData = victimSummary(entries, clonedValues, victim);

    clonedValues._meta.victimSummary = victimSummaryData

    // indirect victims

    clonedValues.indirectVictims = clonedValues.indirectVictims || []

    for (let i = 0; i < clonedValues.indirectVictims.length; i++) {
        const indirectVictimRaw = clonedValues.indirectVictims[i]
        calculateIndirectVictim(clonedValues, victim, indirectVictimRaw, i)
    }

    return clonedValues
}

function calculateIndirectVictim(clonedValues, victim, indirectVictimRaw, victimIndex) {
    const householdIncome = clonedValues.householdIncome
    const indirectVictims = clonedValues.indirectVictims
    const values = indirectVictimRaw.caseData
    const entries = indirectVictimRaw.caseDataForm;
    const indirectVictim = indirectVictimRaw.victim;

    values._meta = values._meta || {}

    for (const value of indirectVictimModel) {
        if (value.items == null || value.items.length === 0) {
            continue
        }

        for (let i = 0; i < value.items.length; i++) {
            const item = value.items[i]
            const itemId = value.id
            const entryName = itemId + i

            if (entryName === 'PRev0') {
                const householdIncomesList = getHouseholdIncomesList(householdIncome);
                const prevCalcResult = getPRevEntryTotal(householdIncomesList, victim, indirectVictims, victimIndex)
                values._meta.PRev = values._meta.PRev || {}
                values._meta.PRev.items = values._meta.PRev.items || []
                values._meta.PRev.items[0] = prevCalcResult
                continue
            }

            const calcVal = subEntryTotal(values, victim, entryName, item, indirectVictim)
            values._meta[itemId] = values._meta[itemId] || {}
            values._meta[itemId].items = values._meta[itemId].items || []
            values._meta[itemId].items[i] = calcVal


            values._meta[itemId].items[i].cascadedTotal = getCascadedTotal(values, indirectVictim, entryName)


            if (values[entryName] != null) {
                values._meta[itemId].items[i].lineCalculatations = values._meta[itemId].items[i].lineCalculatations || {}

                for (let entryIndex = 0; entryIndex < values[entryName].length; entryIndex++) {
                    values._meta[itemId].items[i].lineCalculatations[entryIndex] = values._meta[itemId].items[i].lineCalculatations[entryIndex] || {}
                    values._meta[itemId].items[i].lineCalculatations[entryIndex].lineVictimAmountCalculated = lineVictimAmount(values, entryName, entryIndex, item, victim);
                    values._meta[itemId].items[i].lineCalculatations[entryIndex].lineVictimDiscountedAmountCalculated = lineVictimDiscountedAmount(values, entryName, entryIndex, item, victim);
                    values._meta[itemId].items[i].lineCalculatations[entryIndex].lineAmountCalculated = getLineAmount(values, entryName, entryIndex, item);
                    values._meta[itemId].items[i].lineCalculatations[entryIndex].lineResponsiblePartyAmountCalculated = lineResponsiblePartyAmount(values, entryName, entryIndex, item, victim);
                    values._meta[itemId].items[i].lineCalculatations[entryIndex].lineThirdPartyPayersAmountCalculated = lineThirdPartyPayersAmount(values, entryName, entryIndex, item, victim);
                    values._meta[itemId].items[i].lineCalculatations[entryIndex].dsfLineSummaryCalculated = getDsfLineSummary(values, entryName, entryIndex, item, victim);


                    // TODO check indirectVictim  see this here:
                    // const getLineRefVictim = i => {
                    //         let refVictim = victim;
                    //         const caseROCE = victim.rateOfCompensationEntitlement;
                    //
                    //         if (values[indirectEntry] && values[indirectEntry][i] && values[indirectEntry][i].refVictim && values[indirectEntry][i].refVictim === "indirect") {
                    //             refVictim = caseValues.indirectVictims[victimId].victim;
                    //             refVictim.rateOfCompensationEntitlement = caseROCE;
                    //             refVictim.birthDate = refVictim.birth_date; // to compensate for bad choice of variable names in direct victim. To be fixed!!!
                    //         }
                    //
                    //         return refVictim;
                    //     }

                    let refVictim = victim
                    const caseROCE = victim.rateOfCompensationEntitlement;

                    if (values[entryName] && values[entryName][entryIndex] && values[entryName][entryIndex].refVictim === "indirect") {

                        refVictim = indirectVictim;
                        refVictim.rateOfCompensationEntitlement = caseROCE;
                        // TODO is this necessary?
                        refVictim.birthDate = refVictim.birth_date; // to compensate for bad choice of variable names in direct victim. To be fixed!!!
                    }

                    values._meta[itemId].items[i].lineCalculatations[entryIndex].capLineResponsiblePartyAmountCalculated = capLineResponsiblePartyAmount(values, entryName, entryIndex, item, refVictim);
                    values._meta[itemId].items[i].lineCalculatations[entryIndex].capLineTPPRecourseCalculated = capLineTPPRecourse(values, entryName, entryIndex, item, refVictim);
                    values._meta[itemId].items[i].lineCalculatations[entryIndex].capLineVictimAmountCalculated = capLineVictimAmount(values, entryName, entryIndex, item, refVictim);
                    values._meta[itemId].items[i].lineCalculatations[entryIndex].lineCapitalisedAmountCalculated = lineCapitalisedAmount(values, entryName, entryIndex, item, refVictim);

                    // TODO arrerageType, arrerageIndex
                    values._meta[itemId].items[i].lineCalculatations[entryIndex].lineAmountAfterLossOfOpportunityCalculated = lineAmountAfterLossOfOpportunity(values, entryName, entryIndex, item);

                    if (values[entryName][entryIndex] && values[entryName][entryIndex].arr && values[entryName][entryIndex].arr.past) {
                        // if (values[entryName][i] && values[entryName][i].arr && values[entryName][i].arr.past) {
                        values._meta[itemId].items[i].lineCalculatations[entryIndex].dsfPastArrSummaryCalculated = getDsfPastArrSummary(values, entryName, entryIndex, item, victim);
                    }

                    if (values[entryName][entryIndex] && values[entryName][entryIndex].arr && values[entryName][entryIndex].arr.future) {
                        values._meta[itemId].items[i].lineCalculatations[entryIndex].dsfLineCapitalisedAmountCalculated = dsfLineCapitalisedAmount(values, entryName, entryIndex, item, victim);
                        values._meta[itemId].items[i].lineCalculatations[entryIndex].dsfFutureArrSummaryCalculated = getDsfFutureArrSummary(values, entryName, entryIndex, item, victim);
                        values._meta[itemId].items[i].lineCalculatations[entryIndex].dsfFutureAnnualDamageCalculated = getDsfFutureAnnualDamage(values, entryName, entryIndex, item, victim);
                    }
                }
            }

            const entryItems = values._meta[itemId].items
            values._meta[itemId].entrySummaryCalculated = getEntrySummary(entryItems, itemId, 'single indirect');

        }
    }

    const victimSummaryData = victimSummary(entries, values, indirectVictim);

    values._meta.victimSummary = victimSummaryData
}
