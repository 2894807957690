import React, { useState } from 'react';
import axios from "axios";
import { Link } from "react-router-dom";
import LegalFooter from "../layout/LegalFooter";


const PasswordResetForm = (props) => {
    let token = props.params.token;
    const [password1, setPassword1] = useState("");
    const [password2, setPassword2] = useState("");
    const submitDisabled = password1 !== password2 || !password1 || password1.length < 8;
    const [submitted, setSubmitted] = useState(false);
    const [result, setResult] = useState("");
    const [errorMsg, setErrorMsg] = useState("");
    const [displayResetLink, setDisplayResetLink] = useState(false);
    const [displaySupport, setDisplaySupport] = useState(false);


    const updatePassword = () => {
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };
        let password = "";
        if (!submitDisabled) password = password1;

        const body = JSON.stringify({ token, password });

        axios
            .post("/api/auth/password_reset/confirm/", body, config)
            .then(res => {
                res.data && res.data.status && res.data.status === "OK" && setResult("OK")
            })
            .catch(err => {
                setResult("KO");
                if (err.response.data && err.response.data.status === "notfound") {
                    setErrorMsg("Il semble que le lien de réinitialisation n'est pas valide ou a expiré. ")
                    setDisplayResetLink(true);
                    setDisplaySupport(true);
                }
                else if (err.response.data && err.response.data.password && err.response.data.password.length > 0)
                    setErrorMsg(err.response.data.password[0].slice(0, -1));
                else {
                    setErrorMsg("OUPS ! Une erreur s'est produite, votre mot de passe n'a pas été mis à jour. Réessayez ou renouvelez votre demande de réinitialisation.");
                    setDisplaySupport(true);
                }
            });

    }

    const handleSubmit = e => {
        e.preventDefault();
        setSubmitted(true);
        updatePassword();
    }

    return (
        <div className="col-md-6 m-auto">
            <div className="card card-body mt-5">
                <h2 className="text-center">Réinitialisation du mot de passe</h2>
                {result === "OK" ?
                    <div className="pt-3">
                        <p><strong>Et voilà !</strong></p>
                        <p>Votre changement de mot de passe est bien pris en compte.</p>
                        <p>Vous pouvez désormais <Link to="/identification">vous identifier</Link> avec votre nouveau mot de passe.</p>
                        <p><strong>&Agrave; tout de suite sur Quantum !</strong></p>
                    </div>
                    :
                    <form onSubmit={handleSubmit}>
                        <div className="form-group">
                            <div className="pt-3 pb-4">
                                <p><strong>On a hâte de vous retrouver...</strong></p>
                                <p>Pour réinitialiser votre mot de passe, veuillez compléter les champs ci-dessous (votre nouveau mot de passe doit comporter au moins 8 caractères)&nbsp;: </p>
                            </div>
                            <div className="form-group">
                                <label>Nouveau mot de passe</label>
                                <input
                                  type="password"
                                  className="form-control"
                                  name="password1"
                                  autoFocus
                                  onChange={e => setPassword1(e.target.value)}
                                  value={password1}
                                  required
                                  autoComplete="new-password"
                                />
                            </div>
                            <div className="form-group">
                                <label>Confirmation du nouveau mot de passe</label>
                                <input
                                  type="password"
                                  className="form-control"
                                  name="password2"
                                  onChange={e => setPassword2(e.target.value)}
                                  value={password2}
                                  required
                                  autoComplete="new-password"
                                />
                            </div>
                        </div>
                        {result === "KO" &&
                            <div className="pb-2">
                                <span>{errorMsg}
                                    {displayResetLink && <Link to="/reinitialisation">Faites une nouvelle demande de réinitialisation de mot de passe</Link>}.<br />
                                    {displaySupport && <>Si le problème persiste, vous pouvez nous contacter à <a href="mailto:quantum@juri-solutions.fr">quantum@juri-solutions.fr</a>.</>}
                                </span>
                            </div>
                        }
                        <div className="form-group">
                            {submitted ?
                                <button className="btn btn-primary" type="button" disabled>
                                    <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                    Demande en cours de traitement...
                                </button>
                                :
                                <button className="btn btn-primary" type="submit" disabled={submitDisabled}>
                                    Poursuivre
                                </button>
                            }
                        </div>
                    </form>
                }
            </div>
            <LegalFooter />
        </div>
    )
}

export default PasswordResetForm;
