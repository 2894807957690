import React from 'react';
import PropTypes from "prop-types";
import { makeStyles } from '@material-ui/core/styles';
import DeleteForever from '@material-ui/icons/DeleteForever';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { getVictimListStr } from './calculations';



const useStyles = makeStyles((theme) => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: 'none',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      textAlign: 'justify',
      width: 500,
    },
  }));

const NoDeletionButton = (props) => {

    const { index, incomeId, allocationDetails } = props;


    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
  
    const handleOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };

    const victimsStr = getVictimListStr(allocationDetails[incomeId].victims, false)

  return (
    <>
    <button
      type="button"
      className="btn btn-outline-danger btn-sm p-0"
      onClick={handleOpen}
      title="Supprimer ce revenu du foyer"
    >
      <DeleteForever />
    </button>      
    
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <h2 id="transition-modal-title">Impossible de supprimer ce revenu du foyer</h2>
            <p>Ce revenu est utilisé dans les calculs de perte de revenus des proches pour les victimes indirectes suivantes&nbsp;:&nbsp;{victimsStr}.</p>
          </div>
        </Fade>
      </Modal>
    </>
  )
}

NoDeletionButton.propTypes = {
    index: PropTypes.number,
    incomeId: PropTypes.string,
    allocationDetails: PropTypes.object,
  }
  

export default NoDeletionButton;
