import React from "react";
export function CaseLockedFragment(props) {
    return props.isLocked ? React.createElement("div", { style: {
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(255, 255, 255, 0.5)", // Optional: Semi-transparent white
            zIndex: 10,
            pointerEvents: "all", // Blocks all interactions
        } }) : React.createElement(React.Fragment, null);
}
