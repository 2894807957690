import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getFormValues, reduxForm } from "redux-form";
import VictimTabs from "../case_layout/VictimTabs";
import DirectVictimEntries from "./DirectVictimEntries";
import DisplayHouseholdIncomeForm from "./household_income/displayHouseholdIncomeForm";
import DisplayIncomeLossCalcMethodChoice from "./household_income/displayIncomeLossCalcMethodChoice";

let CaseData = props => {
    const { handleSubmit, victim, caseValues, indirectVictims, user } = props;

    useEffect(() => {
        const interval = setInterval(() => {
            handleSubmit();
        }, 60000);
        return () => clearInterval(interval);
    }, []);

    const [incomeFormOpen, setIncomeFormOpen] = React.useState(false);
    const [methodChoiceOpen, setMethodChoiceOpen] = React.useState(false);

    return (
        <form onSubmit={handleSubmit}>
            {(caseValues) ?
                <>
                    <VictimTabs
                      indirectVictims={indirectVictims}
                      currentVictim={caseValues.currentVictim ? caseValues.currentVictim : 0}
                      directVictim={victim}
                      setIncomeFormOpen={setIncomeFormOpen}
                      setMethodChoiceOpen={setMethodChoiceOpen}
                      caseValues={caseValues}
                    />
                    <DisplayHouseholdIncomeForm
                      open={incomeFormOpen}
                      setIncomeFormOpen={setIncomeFormOpen}
                      householdIncome={caseValues.householdIncome}
                      indirectVictims={indirectVictims}
                    />
                    <DisplayIncomeLossCalcMethodChoice
                      open={methodChoiceOpen}
                      setMethodChoiceOpen={setMethodChoiceOpen}
                    />
                </>
                :
                <DirectVictimEntries victim={victim}/>
            }
            <div className="footer-spacer"></div>
        </form>
    );
};


CaseData = reduxForm({
    form: "caseDataForm",
    enableReinitialize: true,
})(CaseData);


const mapStateToProps = (state) => {
    let caseValues = {};
    let indirectVictims = [];
    let initialValues = {};
    let user;

    if (state.cases && state.cases.case && state.cases.case.json_data) {
        initialValues = state.cases.case.json_data.caseData;
    }
    caseValues = getFormValues("caseDataForm")(state);
    if (caseValues && caseValues.indirectVictims) {
        indirectVictims = caseValues.indirectVictims
    }

    if (state.auth) {
        user = state.auth.user
    }

    return { initialValues, caseValues, indirectVictims, user };
};

CaseData.propTypes = {
    handleSubmit: PropTypes.func,
    dispatch: PropTypes.func,
    entries: PropTypes.array,
    victim: PropTypes.object,
    caseValues: PropTypes.object,
    indirectVictims: PropTypes.array,
    user: PropTypes.object,
};


export default connect(mapStateToProps)(CaseData);
