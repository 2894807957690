import React, { useState } from 'react';
import axios from "axios";
import { connect } from "react-redux";
import { formatDate } from "../cases/utils";
import ExtendSubscription from './ExtendSubscription';
import CancelSubscription from './CancelSubscription';

const UserAccount = (props) => {
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const submitDisabled = password1 !== password2 || !password1 || password1.length < 8;
  const [submitted, setSubmitted] = useState(false);
  const [result, setResult] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [displaySupport, setDisplaySupport] = useState(false);


  const { user, token } = props.auth;

  let email = "";
  let id = "";
  let endOfSubscription = "";
  let expired = false;
  let isAccountManager = false;
  let isBetaTester = false;
  let alreadyCancelled = false;
  let status = ";"
  if (user) {
    email = user.email;
    id = user.id;
    if (user.subscription_end_date)
      endOfSubscription = formatDate(user.subscription_end_date, 0, true)
    expired = user.subscription_expired;
    status = user.stripe_subscription_status
    if (user.stripe_id)
      isAccountManager = true;
    if (user.is_beta_tester)
      isBetaTester = true;
    if (user.cancel_subscription_at_end_date)
      alreadyCancelled = true;

  }

  const updatePassword = () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Token ${token}`,
      },
    };
    let password = "";
    if (!submitDisabled) password = password1;
    const body = JSON.stringify({ token, password });

    axios
      .put(`/api/auth/password_update/${id}/`, body, config)
      .then(res => {
        if (res.data) {
          setResult("OK");
        }
      })
      .catch(err => {
        setResult("KO");
        if (err.response.data && err.response.data.password && err.response.data.password.length > 0)
          setErrorMsg(err.response.data.password[0].slice(0, -1));
        else {
          setErrorMsg("OUPS ! Une erreur s'est produite, votre mot de passe n'a pas été initialisé, veuillez réessayer");
          setDisplaySupport(true);
        }
      });

  }

  const handleSubmit = e => {
    e.preventDefault();
    setSubmitted(true);
    updatePassword();
  }

  return (
    <div className="col-md-6 m-auto">
      <div className="card card-body mt-5">
        <h2 className="text-center">Mon compte utilisateur Quantum</h2>
        <fieldset className="mt-5">
          <legend>Mon profil</legend>
          <div className="form-group row">
            <label htmlFor="staticEmail" className="col-sm-3 col-form-label">Identifiant</label>
            <div className="col-sm-9">
              <span id="staticEmail" className="form-control-plaintext">{email}</span>
            </div>
          </div>
          <div className="form-group row mb-0">
            <label htmlFor="staticEmail" className="col-sm-3 col-form-label">Abonné jusqu'au</label>

            <div className="col-sm-9 d-flex">
              <span id="staticEmail" className={`form-control-plaintext${expired ? " text-danger font-weight-bold" : ""}`} style={{ width: "auto" }}>{endOfSubscription}
              </span>
            </div>
          </div>
          {(isAccountManager || isBetaTester) &&
            <div className="form-group row">
              <div className='col-sm-3'></div>
              <div className="col-sm-9">
                {expired && status !== "active" && status !== "trialing" && <ExtendSubscription active={true} />}
                {(status === "trialing" || status === "active") && !alreadyCancelled && <CancelSubscription active={true} token={token} />}
                {alreadyCancelled && <div>Votre abonnement ne sera pas renouvelé à sa date d'expiration et votre carte bancaire ne sera pas débitée.</div>}
              </div>
            </div>
          }
          {user.referral_code && user.referral_code.length &&
          <div className="form-group row mb-0">
            <label htmlFor="staticEmail" className="col-sm-3 col-form-label">Code parrainage</label>
            <div className="col-sm-9 d-flex">
              <span className="form-control-plaintext" style={{ width: "auto" }}>{user.referral_code}</span>
            </div>
            <div className="col-sm-3" />
            <div className="col-sm-9 d-flex">

              <span style={{ width: "auto" }}>Bénéficiez de <strong>20 % de réduction sur le prochain renouvellement de votre abonnement</strong> en parrainant un nouvel abonné à Quantum.
                                              En renseignant ce code de parrainage, votre filleul bénéficiera également de 20 % de réduction sur sa première année d'abonnement.<br /> 
                                              <strong>Offre cumulable :</strong> en parrainant 2 nouveaux abonnés avant votre prochain renouvellement, vous bénéficierez donc de 40 % de réduction... et d'un abonnement gratuit si vous avez 5 filleuls !<br />
                                              Un nouvel abonné correspond à une personne physique ou morale extérieure à la structure à laquelle est rattaché le parrain.
              </span>
            </div>
          </div>
          }
        </fieldset>
        <fieldset className="mt-5">
          <legend>Modifier mon mot de passe</legend>
          <div className="form-group">
            <div className="pt-3">
              <p>Saisissez un mot de passe contenant au moins 8 caractères&nbsp;: </p>
            </div>
          </div>
          {result === "OK" ?
            <div className="pt-3">
              <p><strong>Et voil&agrave; !</strong></p>
              <p>Votre modification de mot de passe a bien &eacute;t&eacute; prise en compte !</p>
            </div>
            :
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <div className="form-group row">
                  <label htmlFor="password1" className="col-sm-3 col-form-label">Mot de passe</label>
                  <div className="col-sm-9">
                    <input
                      type="password"
                      className="form-control"
                      id="password1"
                      name="password1"
                      autoFocus
                      onChange={e => setPassword1(e.target.value)}
                      value={password1}
                      required
                      autoComplete="new-password"
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label htmlFor="password2" className="col-sm-3 col-form-label">Confirmation</label>
                  <div className="col-sm-9">
                    <input
                      type="password"
                      className="form-control"
                      name="password2"
                      id="password2"
                      onChange={e => setPassword2(e.target.value)}
                      value={password2}
                      required
                      autoComplete="new-password"
                    />
                  </div>
                </div>
              </div>
              {result === "KO" &&
                <div className="pb-2">
                  <span>{errorMsg}
                    {displaySupport && <>Si le problème persiste, vous pouvez nous contacter à <a href="mailto:quantum@juri-solutions.fr">quantum@juri-solutions.fr</a>.</>}
                  </span>
                </div>
              }
              <div className="form-group">
                {submitted ?
                  <button className="btn btn-primary" type="button" disabled>
                    <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                    Demande en cours de traitement...
                  </button>
                  :
                  <button className="btn btn-primary" type="submit" disabled={submitDisabled}>
                    Modifier mon mot de passe
                  </button>
                }
              </div>
              <div align="center"><a href="https://juri-solutions.fr/quantum-cgau/" target="_blank" rel="noopener noreferrer"><em style={{ fontSize: "16px" }}>Conditions générales d&apos;abonnement et d&apos;utilisation</em></a></div>
            </form>
          }
        </fieldset>
      </div>
    </div >
  )
}

const mapStateToProps = state => ({
  auth: state.auth,
});
export default connect(
  mapStateToProps,
)(UserAccount);
