import React, { Fragment } from 'react';
import AmountField from "../../fields/AmountField";
import { getDSFLineSummaryCalculated } from "../calculations_single";

const RenderDSFLineSummary = props => {
    const { index, victim, values, fields, item } = props;
    const lineSummary = getDSFLineSummaryCalculated(values, fields.name, index);

    return (
        <Fragment>
            <tr>
                <td className="col-num pt-0 pb-0" />
                <td className="text-right" colSpan="4">
                    <h5 className="mb-0">Total dépense n° {index + 1}</h5>
                </td>
                <td />
            </tr>
            <tr>
                <td className="col-num pt-0 pb-0" />
                <th scope="row" className="text-right" colSpan="3">Créance de la victime versée en capital</th>
                <td className="col-amount text-left align-bottom pt-0 pb-0">
                    <AmountField readOnly fieldValue={lineSummary.victimAmount} />
                </td>
                <td />
            </tr>
            {values[fields.name][index].arr.annCap.annuity &&
                <tr>
                    <td className="col-num pt-0 pb-0" />
                    <th scope="row" className="text-right" colSpan="3">Créance la victime versée sous forme de rente (montant annualisé)</th>
                    <td className="col-amount text-left align-bottom pt-0 pb-0">
                        <AmountField readOnly fieldValue={lineSummary.annuityAmount} />
                    </td>
                    <td />
                </tr>
            }
            {((values[fields.name][index].arr.annCap.tppCap && values[fields.name][index].arr.annCap.tppCap.length > 0) || (values[fields.name][index].arr.past_tpp && values[fields.name][index].arr.past_tpp.length > 0)) &&
                <tr>
                    <td className="col-num pt-0 pb-0" />
                    <th scope="row" className="text-right" colSpan="3">Recours du/des tiers payeur(s)</th>
                    <td className="col-amount text-left align-bottom pt-0 pb-0">
                        <AmountField readOnly fieldValue={lineSummary.tppRecourse} />
                    </td>
                    <td />
                </tr>
            }
        </Fragment>
    )
}

export default RenderDSFLineSummary;
