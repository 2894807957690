import React from "react";
import PropTypes from "prop-types";
import HelpIcon from "@material-ui/icons/Help";
import Tooltip from '@material-ui/core/Tooltip';
import { helpEntries } from "../cases/physical_injury/constants";

let HelpTip = props => {
  const { helpText } = props;

  return (
    <React.Fragment>
      <Tooltip title={helpEntries[helpText]} leaveDelay={200}>
        <HelpIcon
          fontSize="small"
          variant="contained"
          className="text-secondary smallIcon"
        />
      </Tooltip>
    </React.Fragment>
  );
};

HelpTip.propTypes = {
  helpText: PropTypes.string,
};

export default HelpTip;
