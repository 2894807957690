import React from "react";
import PropTypes from "prop-types";
import Append from "../../fields/Append";

import { Field, change } from "redux-form";
import { connect } from "react-redux";
import HelpTip from "../../../layout/HelpTip";

const RenderCEDropdown = ({
    input,
    label,
    helpText,
    changeHandler,
}) => {
    const ceRateValues = Array.from({ length: 100 }, (_, i) => i + 1).reverse();

    return (
        <div
          className="ceRate form-group ce-rate d-flex flex-fill ml-4 input-group-sm"
        >
            <label className="control-label" htmlFor={input.name}>
                {label}
            </label>
            <div className="d-flex ml-2">
                <Field
                  name={input.name}
                  id={input.name}
                  component="select"
                  dir="rtl"
                  className="custom-select form-control selectNum"
                  onChange={() => changeHandler()}
                >
                    {ceRateValues.map(value =>
                        <option value={value} key={value} default>{value}</option>,
                    )}
                </Field>
                <Append append="%" className=" pl-1" />
            </div>
            {helpText && <HelpTip helpText={helpText} />}
        </div>
    );
};

RenderCEDropdown.propTypes = {
    input: PropTypes.object,
    label: PropTypes.string,
    helpText: PropTypes.string,
    onChangeFunction: PropTypes.func,
};

export default connect()(RenderCEDropdown);
