import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import BtnAddTPPLine from "../../buttons/BtnAddTPPLine";

const RenderActionsCol = props => {
  const { item, line, removeLine, addTPPLine, secondaryLine, capitalTPP } = props;

  return (
    <td className={`col-act ${secondaryLine ? "" : "main-line"}${capitalTPP ? "align-bottom" : ""}`}>
      {(item.multiLine || line.indexOf("TPF") === 0 || line.indexOf("PGP") === 0 || line.indexOf("PInd") === 0) &&
        addTPPLine &&
        line.indexOf("PROV") !== 0 &&
        line.indexOf("TPT") !== 0 &&
        line.indexOf("IP") !== 0 &&
        line.indexOf("DFP") !== 0 &&
        line.indexOf("DFT") !== 0 &&
        line.indexOf("PSUF") !== 0 &&
        (line.indexOf("AUT2Cap") !== 0 || capitalTPP) &&
        (line.indexOf("AUTCap") !== 0 || capitalTPP) &&
        (line.indexOf("TPF") !== 0 || capitalTPP) &&
        (line.indexOf("PInd") !== 0 || capitalTPP) &&
        (line.indexOf("PGP") !== 0 || capitalTPP) &&
        <BtnAddTPPLine addTPPLine={addTPPLine} additionalTitle={capitalTPP && " sous forme de capital"} />
      }
      {removeLine && (
        <button
          type="button"
          className="btn btn-outline-danger btn-sm p-0 mr-1"
          onClick={removeLine}
          title={item.multiLine ? "Supprimer la ligne" : "Effacer les données saisies"}
        >
          <DeleteForeverIcon />
        </button>
      )}
    </td>
  );
};

RenderActionsCol.propTypes = {
  item: PropTypes.object,
  line: PropTypes.string,
  removeLine: PropTypes.func,
  addTPPLine: PropTypes.func,
};

export default connect()(RenderActionsCol);
