import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import { connect, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { change, formValueSelector } from "redux-form";

const DeleteIndirectVictimButton = props => {
  const { victimId, victimTitle, formIndirectVictims } = props;
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = id => {
    if (formIndirectVictims && formIndirectVictims[id])
    {
      let newIndirectVictims = formIndirectVictims;
      newIndirectVictims.splice(id,1)
      dispatch(change("caseDataForm", "currentVictim", id));
      dispatch(change("caseDataForm", "indirectVictims", newIndirectVictims));
      if (newIndirectVictims.length === 0) {
        dispatch(change("caseDataForm", "householdIncome", []));
        dispatch(change("caseDataForm", "incomeLossCalcMethod", ""));
      }
    }
    setOpen(false);
  }

  return (
    <Grid container justify="flex-end">
      <Button color="secondary" size="small" onClick={handleClickOpen}>
        Supprimer cette victime
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Supprimer {victimTitle} ?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Souhaitez-vous vraiment supprimer la victime indirecte "{victimTitle}"&nbsp;?<br/>
            <strong>ATTENTION : cette opération est irréversible.</strong>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" size="small" title="Supprimer la victime indirecte">
            Annuler
          </Button>
          <Button onClick={() => handleConfirm(victimId)} variant="contained" color="secondary" size="small" startIcon={<DeleteIcon />} autoFocus>
            Confirmer la suppression
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}

DeleteIndirectVictimButton.propTypes = {
  victimId: PropTypes.number.isRequired,
  victimTitle: PropTypes.string,
  formIndirectVictims: PropTypes.array,
};

const dataSelector = formValueSelector("caseDataForm");

const mapStateToProps = state => {
  let formIndirectVictims = [];

  if (state.cases && state.cases.case && state.cases.case.json_data) {
    formIndirectVictims = dataSelector(
      state,
      "indirectVictims",
    );
  }

  return { formIndirectVictims };
};

export default connect(mapStateToProps)(DeleteIndirectVictimButton);
