import React, { Fragment } from "react";
import PropTypes from "prop-types";
import AmountField from "../../fields/AmountField"
import AnnuitiesSummary from "./AnnuitiesSummary"
import { getEntrySummaryCalculated } from "../calculations_single";

function EntrySummary(props) {
  const { entry, victim, caseValues } = props;
  let entrySummary = {};

  if (caseValues._meta &&
    caseValues._meta[entry.id] &&
    caseValues._meta[entry.id].items)
    entrySummary = getEntrySummaryCalculated(caseValues,entry.id);

  return (
    <table className="table table-hover table-sm mt-4">
      <tfoot>
        <tr>
          <td className="main-line" />
          <td className="main-line text-right" colSpan={2}>
            <h5 className="mb-0">Total {entry.label.toLowerCase()}<span style={{ fontSize: "0.7rem" }}> (arrérages échus et à échoir)</span></h5>
          </td>
          <td className="main-line col-act" />
        </tr>
        {/* {parseInt(victim.rateOfCompensationEntitlement) < 100 && (
          <tr>
            <td />
            <th scope="row">Dette du/des responsable(s)</th>
            <td className="col-amount">
              <AmountField fieldValue={entrySummary.respPartyTotal} />
            </td>
            <td className="col-act" />
          </tr>
        )} */}
        <tr>
          <td />
          <th scope="row">Créance de la victime versée en capital</th>
          <td className="col-amount">
            <AmountField fieldValue={Math.max(entrySummary.total, 0)} />
          </td>
          <td className="col-act" />
        </tr>
        {entrySummary.annuityTotal > 0 && (
          <AnnuitiesSummary annuityDetails={entrySummary.annuityDetails} annuityTotal={entrySummary.annuityTotal} />
        )}
        {entrySummary.hasTPP &&
          <Fragment>
            <tr>
              <td />
              <th scope="row">Recours du/des tiers payeur(s)</th>
              <td className="col-amount">
                <AmountField fieldValue={entrySummary.tppRecourse} />
              </td>
              <td className="col-act" />
            </tr>
            {entrySummary.tppRecourse < entrySummary.cumulatedTppRecourse &&
              <tr>
                <td />
                <td className="text-right align-bottom" colSpan="2">

                  <small className="text-danger">
                    <strong>En application du droit de préférence, la créance de la victime et le recours du/des tiers payeur(s) s'apprécient sur l'ensemble du poste de {entry.id}, arrérages échus et à échoir confondus.</strong>
                  </small>

                </td>
                <td className="col-act" />
              </tr>
            }
          </Fragment>

        }
        {entrySummary.cascade < 0 &&
          <tr>
            <td />
            <td className="text-right align-bottom" colSpan="2">
              <small className="text-danger">
                <strong>
                  {entry.id === "PGPF" && `Le reliquat de paiement de tiers payeur(s) non imputé de ${Intl.NumberFormat("fr-FR", {
                    style: "currency",
                    currency: "EUR",
                  }).format(-entrySummary.cascade)} ${victim.disableCascade ? "n'est pas reporté automatiquement sur les autres postes de préjudice." : "est reporté automatiquement sur l'éventuel poste d'incidence professionnelle (IP)."}`}
                  {entry.id === "TPF" && `Le reliquat de paiement de tiers payeur(s) au titre de la TPF (${Intl.NumberFormat("fr-FR", {
                    style: "currency",
                    currency: "EUR",
                  }).format(-entrySummary.cascade)}) n'est pas reporté automatiquement sur les autres postes de préjudice.`}
                  {entry.id === "PInd" && `Le reliquat de paiement de tiers payeur(s) au titre de la perte en industrie (${Intl.NumberFormat("fr-FR", {
                    style: "currency",
                    currency: "EUR",
                  }).format(-entrySummary.cascade)}) n'est pas reporté automatiquement sur les autres postes de préjudice.`}
                </strong>
              </small>
            </td>
            <td className="col-act" />
          </tr>
        }
      </tfoot>
    </table>
  );
}

EntrySummary.propTypes = {
  entry: PropTypes.object,
  victim: PropTypes.object,
  caseValues: PropTypes.object,
};

export default EntrySummary;
