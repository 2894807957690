import axios from "axios";
import { returnErrors } from "./errors";

import {
    AUTH_ERROR,
    LOGIN_FAIL,
    LOGIN_SUCCESS,
    LOGOUT_SUCCESS,
    REGISTER_FAIL,
    REGISTER_SUCCESS,
    USER_LOADED,
    USER_LOADING, USER_UPDATE_FAIL, USER_UPDATE_LOADING, USER_UPDATED,
} from "./types";

function handleError(dispatch, err) {
    if (!err) {
        return
    }

    if (err.response) {
        dispatch(returnErrors(err.response.data, err.response.status));
    } else {
        dispatch(returnErrors({ title: [err.toString()] }));
    }
}

// Update user details
export const updateUser = (updatedFields) => (dispatch, getState) => {
    dispatch({ type: USER_UPDATE_LOADING });

    axios
        .patch("/api/auth/user", updatedFields, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: USER_UPDATED,
                payload: res.data,
            });
        })
        .catch(err => {
            handleError(dispatch, err);
            dispatch({ type: USER_UPDATE_FAIL });
        });
};


// Check token and load user
export const loadUser = () => (dispatch, getState) => {
    // User loading
    dispatch({ type: USER_LOADING });

    axios
        .get("/api/auth/user", tokenConfig(getState))
        .then(res => {
            dispatch({
                type: USER_LOADED,
                payload: res.data,
            });
        })
        .catch(err => {
            handleError(dispatch, err)
            dispatch({ type: AUTH_ERROR });
        });
};

// Login user
export const login = (email, password) => dispatch => {
    // Headers
    const config = {
        headers: {
            "Content-Type": "application/json",
        },
    };

    // Request body
    const body = JSON.stringify({ email, password });

    axios
        .post("/api/auth/login", body, config)
        .then(res => {
            dispatch({
                type: LOGIN_SUCCESS,
                payload: res.data,
            });
        })
        .catch(err => {
            handleError(dispatch, err)
            dispatch({ type: LOGIN_FAIL });
        });
};

// Logout user
export const logout = () => (dispatch, getState) => {
    dispatch(returnErrors({ title: ['Some error'] }));
    axios
        .post("/api/auth/logout/", null, tokenConfig(getState))
        .then(res => {
            dispatch({
                type: LOGOUT_SUCCESS,
            });
        })
        .catch(err => {
            handleError(dispatch, err)
        });
};

// Register user
export const register = ({ email }) => dispatch => {
    // Headers
    const config = {
        headers: {
            "Content-Type": "application/json",
        },
    };

    // Request body
    const body = JSON.stringify({ email });

    axios
        .post("/api/auth/register", body, config)
        .then(res => {
            dispatch({
                type: REGISTER_SUCCESS,
                payload: res.data,
            });
        })
        .catch(err => {
            handleError(dispatch, err)
            dispatch({ type: REGISTER_FAIL });
        });
};

// Setup config with the token - helper function
export const tokenConfig = getState => {
    // Get the token from the state
    const token = getState().auth.token;

    // Headers
    const config = {
        headers: {
            "Content-Type": "application/json",
        },
    };

    // if token, add it to headers
    if (token) {
        config.headers["Authorization"] = `Token ${token}`;
    }
    return config;
};
