export var CASES_LOADING = "CASES_LOADING";
export var CASES_RECEIVED = "CASES_RECEIVED";
export var CASE_VARIANTS_LOADING = "CASE_VARIANTS_LOADING";
export var GET_CASE = "GET_CASE";
export var CASES_LOADING_CANCEL = "CASES_LOADING_CANCEL";
export var GET_VARIANT_PARENT = "GET_VARIANT_PARENT";
export var GET_CASE_VARIANTS = "GET_CASE_VARIANTS";
export var UPDATE_CASE_VERSION = "UPDATE_CASE_VERSION";
export var DUPLICATE_CASE = "DUPLICATE_CASE";
export var UPDATE_CASE = "UPDATE_CASE";
export var UPDATE_CASE_TITLE = "UPDATE_CASE_TITLE";
export var DELETE_CASE = "DELETE_CASE";
export var ADD_CASE = "ADD_CASE";
export var GET_ERRORS = "GET_ERRORS";
export var CREATE_MESSAGE = "CREATE_MESSAGE";
export var USER_LOADING = "USER_LOADING";
export var USER_LOADED = "USER_LOADED";
export var USER_UPDATE_LOADING = "USER_UPDATE_LOADING";
export var USER_UPDATED = "USER_UPDATED";
export var USER_UPDATE_FAIL = "USER_UPDATE_FAIL";
export var TEAM_LOADING = "TEAM_LOADING";
export var TEAM_LOADED = "TEAM_LOADED";
export var AUTH_ERROR = "AUTH_ERROR";
export var LOGIN_SUCCESS = "LOGIN_SUCCESS";
export var LOGIN_FAIL = "LOGIN_FAIL";
export var LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export var REGISTER_SUCCESS = "REGISTER_SUCCESS";
export var REGISTER_FAIL = "REGISTER_FAIL";
export var IS_CASE_LIST = "IS_CASE_LIST";
export var IS_NOT_CASE_LIST = "IS_NOT_CASE_LIST";
